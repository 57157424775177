import React from 'react'
import "../About.css"
import leftCollage from "../../../Asset/About/about-top-left-portion2.png"
import aboutMobileTop from "../../../Asset/About/about-mobile-top-image.png"
import aboutMobileBottom from "../../../Asset/About/about-mobile-bottom-image.png"
const TopContent = () => {
  return (
    <div className='pt-44 w-full'>

      <div id="heading" className='mb-10 md:mb-24'>
        <h1 className='text-[var(--dark-blue)] text-[2.5rem] md:text-6xl lg:text-7xl  font-ClashGrotesk font-medium text-center'>What is Net Gala?</h1>
      </div>

      {/* For mobile devices only look here */}
      <div className='flex flex-col justify-center items-center md:hidden'>
        {/* upper image */}
        <div className='w-[93%]'>
          <img src={aboutMobileTop} alt="" />
        </div>
        {/* lower div */}
        <div className='w-[93%] -mt-12'>
          <img src={aboutMobileBottom} alt="" />
        </div>
      </div>


      {/* For desktop and medium devices look here */}
      <div className='w-full md:flex md:flex-row p-10 relative hidden '>
        {/* Left part here */}
        <div id='TopContent-left-portion' className='w-[90%] md:w-[53%]  flex justify-end mr-[-2.5rem] z-10 pt-1'>
          <div id='left-card-backgroud' className='w-[95%] flex justify-end items-center '>
            <img src={leftCollage} alt="" />
          </div>
        </div>

        {/* Right part here */}
        <div id='top-content-right-portion' className='w-[90%] md:w-[47%] flex justify-start p-1'>
          <div id="right-card-background" className='w-[95%]'>
            <div id="text-area-right-box" className='flex flex-col gap-4 md:gap-8 2xl:gap-12 justify-center items-center w-[85%] h-[90%] text-[var(--white)] p-4  mx-auto'>
              <h1 className='font-ClashGrotesk font-medium text-base md:xl lg:text-2xl xl:text-5xl  text-center'>Do you mean
                “The Net Gala”?</h1>
              <p className="font-manrope text-xs md:text-xs lg:text-sm xl:text-xl md:font-normal lg:font-medium">No, we literally mean <span className='font-medium'>The Net Gala</span>.
                The Net Gala began as a weekend sports series and has become a vibrant sporting community. We’re all about reviving recreational sports and creating a welcoming space for everyone.</p>
              <p className="font-manrope text-xs md:text-xs lg:text-sm xl:text-xl md:font-normal  lg:font-medium">Whether you're rekindling a passion for sports or exploring a new hobby, we’re here to help you embrace an active lifestyle, play, compete, and connect. Net Gala is about getting you back into the game or learning something new—because no matter your age, it’s never too late to jump in.</p>


            </div>

          </div>
        </div>
      </div>

    </div>
  )
}

export default TopContent
