import React from 'react'
import './currentEvent.css'
import MoreEventCard from '../EventComponents/MoreEventCard'


const moreEvents = ({ eventData }) => {
  return (
    <>
      <MoreEventCard eventData={eventData} />
    </>
  )
}

export default moreEvents
