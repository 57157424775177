import React from "react";
import mobileApp from '../../../Asset/mobile-app.svg';

const MobileAppFeature = ({onOpenMembershipModal}) => {

  return (
    <div className="mobile-app-feature-content flex flex-col-reverse md:flex-row md:bg-[var(--light-yellow)] bg-[var(--very-light-yellow)] pt-12 ">

      {/* Left Side mobile app feature image */}
      <div className="mobile-app-feature-content-left  flex justify-center items-center text-[var(--gray-black)] w-full md:w-[45%] md:pl-3">

        <div className="mobile-app-feature-content-left-inner-container flex flex-col items-start   w-[85%] ">
          {/* Heading part */}
          <div className="mobile-app-feature-content-left-header  md:text-[3.5rem] text-[2.25rem] font-ClashGrotesk font-medium  leading-tight md:text-left text-center mb-6 md:mb-12"> 
            <p className=" md:text-left text-center ">Explore Membership Packages – Exclusively on Our Mobile App</p>

          
          </div>

          {/* Description and button */}
          <div className="flex flex-col md:items-start  items-center">
            <p className="mobile-app-feature-content-left-description text-base md:text-xl  font-manrope font-[470] text-center md:text-left mb-7 text-[#464646]">We are gearing up to launch membership packages that’ll pair you up for different sports, include coaching sessions, social mixers, and a gamified way to dive into our community. Stay tuned—you won’t want to miss out.</p>
            <button onClick={onOpenMembershipModal} className="mobile-app-feature-content-left-btn text-base md:text-lg  bg-[var(--purple-blue)] md:py-4 py-3 px-12 md:w-[70%] w-[95%] text-[var(--white)] rounded-xl font-manrope font-semibold">Join our membership waitlist</button>
          </div>
        </div>
      </div>

      {/* Right Side mobile app feature image */}

      <div className="mobile-app-feature-content-right mb-12 md:mb-0  flex justify-center items-center w-full md:w-[55%] ">
        <img src={mobileApp} alt="Mobile Application" className="mobile-app-img lg:w-[85%]" />
      </div>

    </div>
  );
};

export default MobileAppFeature;