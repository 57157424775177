import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "./stayInTouchModel.css"

const StayInTouchModel = ({ onClose, toggleLoader }) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        agreeTerms: false,
        subscribeUpdates: false
    });

    const [games, setGames] = useState([]);
    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);

    // Fetch games data when component is mounted
    useEffect(() => {
        const fetchGames = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/sports/`);
                setGames(response.data);
            } catch (error) {
                console.error('Failed to fetch games:', error);
            }
        };

        fetchGames();

        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const validateFields = () => {
        const newErrors = {};
        if (!formData.firstName) newErrors.firstName = true;
        if (!formData.lastName) newErrors.lastName = true;
        if (!validateEmail(formData.email)) newErrors.email = true;
        if (!validateMobile(formData.mobile)) newErrors.mobile = true;
        if (!formData.agreeTerms) newErrors.agreeTerms = true;

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Check if all input fields are filled and checkbox is checked
    const checkFormValidity =
        formData.firstName.trim() !== '' &&
        formData.lastName.trim() !== '' &&
        formData.email.trim() !== '' &&
        formData.mobile.trim() !== '' &&
        formData.agreeTerms;


    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateMobile = (mobile) => {
        const mobileRegex = /^[0-9]{10}$/;
        return mobileRegex.test(mobile);
    };

    const registerClient = async (e) => {
        e.preventDefault();

        if (!validateFields()) return;

        try {
            toggleLoader();

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/prospect-users/`, {
                FirstName: formData.firstName,
                LastName: formData.lastName,
                MobileNumberExt: "+91",
                MobileNumber: formData.mobile,
                EmailAddress: formData.email,
                Notification: formData.subscribeUpdates,
                ProspectEntry: "Signup",
            },
                {
                    headers: {
                        "Content-Type": "application/json",
                        accept: "application/json",
                    },
                });

            if (response.status === 201) {
                alert('Registration successful!');
                onClose();
            }
        } catch (error) {
            alert('Registration failed. Please try again.');
        } finally {
            toggleLoader();
        }
    };
    const handleToggle = (sportId) => {
        setFormData((prevData) => ({
            ...prevData,
            [`game_${sportId}`]: !prevData[`game_${sportId}`]
        }));
    };


    //prevents scrolling of page when modal opened and resumes scrolling when modal closed
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => document.body.style.overflow = 'unset';
    }, []);


    return (
        <div className="stay-in-touch-model fixed inset-0 bg-black bg-opacity-60 flex justify-center md:items-center items-end z-50 ">
            <div className="relative bg-[var(--background-light-gray)] md:bg-[var(--white)] rounded-t-3xl md:rounded-3xl shadow-lg w-[100%] md:max-w-5xl max-h-[96vh] md:h-[60vh] md:max-h-[95vh] md:mx-4 flex flex-col md:flex-row p-3 md:p-0">
                <button onClick={onClose} className="absolute top-4 right-7 md:top-3 md:right-4 text-lg text-gray-500 hover:text-gray-700">
                    &#x2715;
                </button>
                <div className="flex flex-col lg:flex-row items-center  md:justify-between w-full">
                    {/* Left Form Section */}
                    <div className="w-full lg:w-[55%] mb-6 lg:mb-0 h-[80%]">
                        {/* <h2 className="text-2xl font-semibold mb-4"></h2> */}
                        <div className='w-[95%] md:w-[82%] mx-auto flex flex-col md:flex-row justify-between md:mb-3'>
                            <h2 className="text-[1.75rem] md:text-[2rem] leading-[3.5rem] md:mb-4 font-ClashGrotesk font-medium text-[var(--dark-blue)]">Stay in Touch</h2>
                            <p className='font-manrope text-xs mt-auto md:mb-6 mb-2'><span className='text-red-500'>*</span>Required</p>
                        </div>
                        <div className="md:space-y-4 space-y-2 overflow-y-auto  h-[13rem] md:h-auto ">
                            <div className='flex flex-col md:flex-row md:gap-4 gap-3    md:pl-14 '>
                                <div className="flex flex-col  md:w-[15.5rem]">
                                    <input
                                        type="text"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        placeholder="First Name *"
                                        className={`p-2 border ${errors.firstName ? 'border-red-500' : 'border-gray-300'} rounded-lg bg-[var(--white)]`}
                                    />
                                </div>
                                <div className="flex flex-col  md:w-[15.5rem]">
                                    <input
                                        type="text"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        placeholder="Last Name *"
                                        className={`p-2 border ${errors.lastName ? 'border-red-500' : 'border-gray-300'} rounded-lg bg-[var(--white)]`}
                                    />
                                </div>
                            </div>

                            <div className='flex md:gap-4 gap-3 flex-col md:flex-row  md:pl-14 '>
                                <div className="flex flex-col  md:w-[15.5rem]">
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        placeholder="Email Address *"
                                        className={`p-2 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-lg bg-[var(--white)]`}
                                    />
                                </div>
                                <div className="flex flex-col  md:w-[15.5rem]">
                                    <input
                                        type="text"
                                        name="mobile"
                                        value={formData.mobile}
                                        onChange={handleChange}
                                        placeholder="Mobile Number *"
                                        className={`p-2 border ${errors.mobile ? 'border-red-500' : 'border-gray-300'} rounded-lg bg-[var(--white)]`}
                                    />
                                </div>
                            </div>

                            {/* Checkboxes Here */}

                            <div className='w-full md:flex md:flex-col gap-3 hidden'>
                                <div className="checkbox-container md:flex items-center md:pl-14 pt-5  ">
                                    <input type="checkbox" name="subscribeUpdates" checked={formData.subscribeUpdates} onChange={handleChange} className="custom-checkbox" id="signup-newsletter" />
                                    <label htmlFor="signup-newsletter" className="mr-2"></label>
                                    <span className='font-manrope font-semibold text-xs text-[var(--gray-brown)]'>Keep me updated on more events and news from Net Gala</span>
                                </div>
                                <div className="checkbox-container md:flex  items-center md:pl-14 pb-5">
                                    <input type="checkbox" name="agreeTerms" checked={formData.agreeTerms} onChange={handleChange} className={`custom-checkbox`} id="signup-terms" />
                                    <label htmlFor="signup-terms" className="mr-2" style={errors.agreeTerms ? { borderColor: 'rgba(239, 68, 68, 1)' } : {}}></label>
                                    <div className='font-manrope font-semibold text-xs text-[var(--gray-brown)]'>
                                        I agree to the{' '}
                                        <Link to="/terms">
                                            <u className="text-[var(--dark-blue)]">
                                                Net Gala Terms of Service
                                            </u>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Register button that triggers form submission for desktop view */}
                        <div className='w-[90%] md:mt-4 md:flex justify-center hidden ml-9'>
                            <button type="button" onClick={registerClient} className={`w-[92%] mx-auto py-3 mb-8 rounded-lg  font-manrope font-semibold text-[1.1rem] text-[var(--green-button-text-black))] mt-auto ${!checkFormValidity? 'bg-[var(--button-disable-light-gray)] cursor-default' : 'bg-[var(--button-green)]'}`}>
                                Register
                            </button>
                        </div>

                    </div>

                    {/* Right Games Section */}
                    <div className="w-full lg:w-[41%] md:h-full md:rounded-r-3xl flex flex-col justify-start items-start md:p-4 bg-[var(--background-light-gray)] overflow-hidden md:overflow-auto">
                        {games.length > 0 ? (
                            <>
                                <h3 className="md:text-base text-xs font-manrope text-[var(--dark-gray-text-color)] md:text-[var(--dark-blue)] font-semibold mb-4 md:mt-8 md:ml-6">
                                    Which activities are you interested in?
                                </h3>

                                {/* Desktop (Checkbox-based) */}
                                <div className="hidden md:block w-full overflow-y-auto max-h-32 md:max-h-max">
                                    <ul className="w-full checkbox-container">
                                        {games.map((game) => (
                                            <li key={game.SportId} className="p-2 border-b border-gray-300 flex items-center">
                                                <input
                                                    type="checkbox"
                                                    name={`game_${game.SportId}`}
                                                    checked={formData[`game_${game.SportId}`] || false}
                                                    onChange={handleChange}
                                                    className="mr-2 custom-checkbox"
                                                    id={`sports_${game.SportType}`}
                                                />
                                                <label htmlFor={`sports_${game.SportType}`} className="mr-2">

                                                </label>
                                                <span className='font-manrope font-semibold text-[var(--gray-brown)]'>{game.SportType}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                {/* Mobile filter buttons */}
                                <div className="block md:hidden w-full overflow-y-auto max-h-[8.5rem] md:max-h-max py-2">
                                    <ul className="w-full flex flex-wrap gap-3">
                                        {games.map((game) => (
                                            <li key={game.SportId}>
                                                <button
                                                    onClick={() => handleToggle(game.SportId)}
                                                    className={`flex items-center px-4 py-2 rounded-md text-sm font-medium transition-colors text-[var(--filter-button-text-purple)] ${formData[`game_${game.SportId}`]
                                                        ? 'bg-[var(--filter-button-background-purple)]  border-[0.5px] border-[var(--filter-button-border-purple)]'
                                                        : 'bg-white text-gray-700 border border-gray-300'
                                                        }`}
                                                >
                                                    {game.SportType}
                                                    {formData[`game_${game.SportId}`] && (

                                                        <span
                                                            className="ml-2 text-[#5C1BDC87] text-xl font-bold cursor-pointer border-2 border-solid border-[#5C1BDC87] rounded-full w-5 h-5 flex items-center justify-center "
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleToggle(game.SportId);
                                                            }}
                                                        >
                                                            {/* cross button here */}
                                                            <span className='text-[0.6rem] '>&#10006;</span>

                                                        </span>
                                                    )}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </>
                        ) : ""}


                        {/* Checkbox for mobile view */}
                        <div className="checkbox-container flex items-center md:pl-14 pt-5  md:hidden">
                            <input id='mobile-keepMeUpdated' type="checkbox" name="subscribeUpdates" checked={formData.subscribeUpdates} onChange={handleChange} className="custom-checkbox mr-2" />
                            <label htmlFor='mobile-keepMeUpdated' className='mr-2'></label>
                            <span className='font-manrope font-semibold text-xs text-[var(--gray-brown)]'>Keep me updated on more events and news from Net Gala</span>
                        </div>

                        <div className="checkbox-container flex md:items-center md:pl-14 pb-3 pt-2 w-full md:hidden">

                            <input id='mobile-ng-terms' type="checkbox" name="agreeTerms" checked={formData.agreeTerms} onChange={handleChange} className={`custom-checkbox mr-2 ${errors.agreeTerms ? 'border-red-500' : ''} `} />
                            <label htmlFor='mobile-ng-terms' className='mr-2' style={errors.agreeTerms ? { borderColor: 'rgba(239, 68, 68, 1)' } : {}}>
                            </label>
                            <div className='font-manrope font-semibold text-xs text-[var(--gray-brown)]'>
                                I agree to the{' '}
                                <Link to="/terms">
                                    <u className="text-[var(--dark-blue)]">
                                        Net Gala Terms of Service
                                    </u>
                                </Link>
                            </div>
                            <br />

                        </div>

                        {/* Register button that triggers form submission */}
                        <div className='w-full md:mt-4 flex justify-center md:hidden'>
                            <button type="button" onClick={registerClient} className={`w-[92%] mx-auto py-3 mb-8 rounded-lg  font-manrope font-semibold text-[1.1rem] text-white ${!checkFormValidity? 'bg-[var(--button-disable-light-gray)] cursor-default' : 'bg-[var(--purple-blue)]'}`}>
                                Register
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default StayInTouchModel;
