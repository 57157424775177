import React, { useState, useEffect } from "react";
import Axios from "axios";

const ShowSponsors = ({ eventData }) => {
  const [sponsorImages, setSponsorImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        // Use Promise.all to fetch images for all sponsors in parallel
        const imagePromises = eventData.sponsors.map(async (sponsor) => {
          if (!sponsor.SponsorImageFileId) {
            return { sponsorId: sponsor.SponsorId, imageUrl: "" };
          }

          try {
            const res = await Axios.get(
              `${process.env.REACT_APP_BASE_URL}/files/${sponsor.SponsorImageFileId}/download`,
              { responseType: "blob" }
            );
            const imageURL = URL.createObjectURL(res.data);
            return { sponsorId: sponsor.SponsorId, imageUrl: imageURL };
          } catch (error) {
            console.error("Error fetching image for sponsor", sponsor.SponsorId, error);
            return { sponsorId: sponsor.SponsorId, imageUrl: "" };
          }
        });

        // Wait for all image fetches to complete
        const images = await Promise.all(imagePromises);

        // Update the sponsorImages state with the fetched image URLs
        setSponsorImages(images);
      } catch (error) {
        console.error("Error fetching sponsor images:", error);
      }
    };

    // Check if eventData and sponsors exist and are non-empty
    if (eventData?.sponsors?.length > 0) {
      fetchImages();
    }
  }, [eventData]);

  // Render nothing if no sponsors are available
  if (!eventData?.sponsors?.length) {
    return null;
  }

  return (
    <div className="flex gap-4">
      {eventData.sponsors.map((sponsor) => {
        // Find the image URL for the current sponsor
        const sponsorImage = sponsorImages.find(
          (img) => img.sponsorId === sponsor.SponsorId
        )?.imageUrl;

        return (
          <div key={sponsor.SponsorId} className="flex flex-col items-center">
            {sponsorImage ? (
              <img
                className="w-20 h-20 object-contain"
                src={sponsorImage}
                alt={sponsor.SponsorName}
              />
            ) : (
              <div className="text-lg font-medium">{sponsor.SponsorName}</div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ShowSponsors;
