import React from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import logo from '../../Asset/logo.svg';
import logoOther from '../../Asset/logo-other-pages.svg';
import EventBgLight from "../../Asset/HeaderMobile/mobile-nav-bg-event-light.png"
import EventBgDark from "../../Asset/HeaderMobile/mobile-nav-bg-event-dark.png"
import AboutBgLight from "../../Asset/HeaderMobile/mobile-nav-bg-about-light.png"
import AboutBgDark from "../../Asset/HeaderMobile/mobile-nav-bg-about-dark.png"

const NavigationMobile = ({ isOpen, onClose }) => {
  let { eventId } = useParams();
  const location = useLocation();
  const isHomepage = location.pathname === "/";
  return (
    <div className={`fixed inset-0 z-50 ${isOpen ? 'block' : 'hidden'}`}>
      {/* Overlay with blur */}
      <div
        className="absolute inset-0 bg-gray-400 bg-opacity-50 backdrop-blur-lg"
        onClick={onClose}
      ></div>

      {/* Mobile Menu */}
      <div className="absolute top-0 right-0 w-full h-full bg-transparent flex flex-col justify-center px-8">
        {/* Logo */}
        <div className="absolute top-2  logo w-1/2  md:ml-10 ">
          <Link to="/">
            <img
              src={isHomepage ? logo : logoOther}
              alt="Net Gala Logo"
              className="w-32"
            />
          </Link>
        </div>

        {/* Close Button */}
        <button
          className="absolute top-8 right-6 text-2xl font-bold text-[var(--dark-blue)]"
          onClick={onClose}
        >
         &#x2715;
        </button>

        {/* Navigation Links */}
        <nav className="flex flex-col space-y-5 text-left h-[70%] justify-start mt-20">
          <Link to="/events-listing" onClick={onClose}>
            <div className={` w-full rounded-2xl p-5 ${isHomepage ? 'text-[var(--dark-blue)]' : 'text-[var(--white)]'}`} style={{
              backgroundImage: isHomepage
                ? `url(${EventBgLight})`
                : `url(${EventBgDark})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}>
              <button className="  font-ClashGrotesk text-[1.5rem] font-medium">Events</button>
              <p className='font-manrope  text-base font-semibold pt-1 w-[80%]'>Explore our wide range of sports event offerings! </p>
            </div>

          </Link>

          {/* <Link to="/memberships" onClick={onClose}>
            <button className="text-3xl font-semibold text-white">Memberships</button>
          </Link> */}

          <Link to="/about" onClick={onClose}>

            <div className={` w-full rounded-2xl p-5 ${isHomepage ? 'text-[var(--dark-blue)]' : 'text-[var(--white)]'}`} style={{
              backgroundImage: isHomepage
                ? `url(${AboutBgLight})`
                : `url(${AboutBgDark})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}>
              <button className="  font-ClashGrotesk text-[1.5rem] font-medium">About</button>
              <p className='font-manrope text-base font-semibold pt-1 w-[80%]'>Our origin story and what makes us
              stand out from the rest!</p>
            </div>
          </Link>
        </nav>
      </div>
    </div>
  );
};

export default NavigationMobile;
